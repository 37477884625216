<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="agroindustrias"
      :headers="headers"
      :slots="[]"
      :actions="actions"
    >
      <template v-slot:btnCadastro>
        <v-row>
          <v-col>
            <v-btn
              color="green darken-1"
              dark
              class="rounded-lg"
              :loading="loadingSheet"
              @click="exportSheet"
            >
              <v-icon class="mr-2">mdi-file-excel</v-icon>

              <span style="font-size: 16px; font-weight: 600">Exportar</span>
            </v-btn>
          </v-col>
          <v-col>
            <router-link :to="`/${$user.get().role}/agroindustrias/form`">
              <v-btn color="success"> Nova Agroindústria </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
    </crud-list>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          :fullscreen="$vuetify.breakpoint.xs"
          max-width="600px"
        >
          <v-card elevation="2" outlined tile>
            <v-btn
              color="black"
              title="Cancelar"
              small
              outlined
              dark
              elevation="2"
              style="position: absolute; top: 5px; right: 5px"
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title> Associar consultor </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.consultor"
                    autocomplete="autocomplete_off_hack_xfr4!k"
                    label="Consultor *"
                    :items="consultor"
                    :rules="requiredRules"
                    item-text="Nome"
                    item-value="id"
                    :disabled="!loadingData.consultor"
                    :loading="!loadingData.consultor"
                    required
                  />
                </v-col>
                <v-col class="pa-1" cols="12">
                  <v-menu
                    v-model="menuDataIni"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="moment(dataInicio).format('DD/MM/YYYY')"
                        label="Data de ínicio"
                        prepend-icon="mdi-calendar"
                        color="teal"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dataInicio"
                      style="margin: 0px"
                      :max="new Date().toISOString()"
                      color="teal"
                      locale="pt-br"
                      @input="menuDataIni = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row justify="center" style="margin: 0px">
                <v-btn
                  :disabled="!loadingData.consultor || !form.consultor"
                  :loading="loadingData.loadingConsultor"
                  elevation="2"
                  color="success"
                  @click="createConsultorAgroindustria"
                >
                  Finalizar Relação
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import { JsonToExcel } from '../../utils/Worksheet'

export default {
  data() {
    return {
      dialog: false,
      valid: null,
      menuDataIni: false,
      loadingSheet: false,
      dataInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selectedConsultor: '',
      loadingData: {
        consultor: false,
        loadingConsultor: false,
      },
      requiredRules: [v => !!v || '* Obrigatório'],
      actions: [
        {
          title: 'Editar agroindústria',
          color: 'yellow darken-3',
          click: item =>
            this.$router.push({
              path: 'agroindustrias/form',
              query: {
                CodAgroindustria: item.CodAgroindustria,
              },
            }),
          icon: 'mdi-pencil',
        },
        {
          title: 'Excluir agroindústria',
          color: 'red darken-3',
          click: item => this.deleteItem(item),
          icon: 'mdi-delete',
        },
      ],
      loading: true,
      headers: [
        {
          text: '',
          sortable: false,
          width: '80px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'pessoa.pessoajuridica.NomeFantasia',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'CNPJ',
          value: 'pessoa.pessoajuridica.Cnpj',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Porte',
          value: 'Porte',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Telefone',
          value: 'pessoa.TelRes',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Estado',
          value: 'pessoa.endereco.cidade.estado.Sigla',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Cidade',
          value: 'pessoa.endereco.cidade.Nome',
          width: 'auto',
        },
      ],
      form: {
        consultor: '',
      },
      agroindustrias: [],
      consultor: [],
    }
  },
  created() {
    this.getAgroindustrias()
    this.getConsultores()
  },
  methods: {
    getAgroindustrias() {
      this.api.get.agroindustrias().then(data => {
        this.agroindustrias = data
        this.loading = false
      })
    },
    exportSheet() {
      this.loadingSheet = true

      new JsonToExcel(
        this.agroindustrias,
        this.headers,
        'agroindustrias.xlsx',
        'Agroinsdustria',
      )

      this.loadingSheet = false
    },
    deleteItem(item) {
      this.Swal.fire({
        title: 'Apagar Agroindústria',
        text: 'Você tem certeza que deseja remover esta agroindústria?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff5252',
        cancelButtonColor: '#999',
        confirmButtonText: 'Sim, desejo removê-la!',
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
          return this.api.delete.agroindustria(item.CodAgroindustria).then(
            result => result,
            () => {
              this.Swal.fire({
                icon: 'error',
                title: 'Ocorreu algum erro!',
                text: 'Não foi possivel fazer a exclusão!',
              })
            },
          )
        },
      }).then(result => {
        if (result.value) {
          this.Swal.fire({
            title: 'Sucesso',
            text: 'Esta agroindustria foi removida com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })

          this.loading = true
          this.getAgroindustrias()
        }
      })
    },
    getConsultores() {
      this.api.get.consultores().then(data => {
        this.consultor = data
        this.loadingData.consultor = true
      })
    },
    createConsultorAgroindustria() {
      if (!this.form.consultor) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )

        return
      }

      this.loadingData.loadingConsultor = true
      this.loadingData.consultor = false

      const data = {
        user: this.form.consultor,
        agroindustria: this.selectedConsultor,
        dataInicio: this.dataInicio,
      }

      const find = this.consultor.find(item => item.id === data.user)
      let status = false
      this.agroindustrias = this.agroindustrias.map(item => {
        if (data.agroindustria === item.CodAgroindustria) {
          const findUser = item.consultores.find(
            consultor => consultor.usuario.CodPessoa === data.user,
          )

          if (findUser) {
            this.Swal.fire(
              'Atenção',
              'Este consultor já está cadastrado para esta agroindustria!',
              'warning',
            )

            this.form.consultor = ''
            this.dataInicio = ''
            this.loadingData.loadingConsultor = false
            this.loadingData.consultor = true
            this.dataInicio = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)

            status = false
          } else {
            status = true
          }
        }

        return item
      })

      if (!status) return

      this.api.save
        .agronegocioAgroindustria(data)
        .then(result => {
          if (result) {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'Agroindústria vinculada com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })

            this.agroindustrias = this.agroindustrias.map(item => {
              if (data.agroindustria === item.CodAgroindustria) {
                item.consultores.push({
                  usuario: {
                    id: find.id,
                    name: find.Nome,
                    CodPessoa: find.id,
                  },
                  DataInicio: data.dataInicio,
                })
              }

              return item
            })

            this.form.consultor = ''
            this.dataInicio = ''
            this.dialog = false
            this.loadingData.loadingConsultor = false
            this.loadingData.consultor = true
            this.dataInicio = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
          }
        })
        .catch(() => {
          this.Swal.fire(
            'Atenção',
            'Não foi possível vincular esta agroindústria ao consultor! Tente novamente.',
            'warning',
          )

          this.form.consultor = ''
          this.dataInicio = ''
          this.dialog = false
          this.loadingData.loadingConsultor = false
          this.loadingData.consultor = true
          this.dataInicio = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
        })
    },
  },
}
</script>
